/* eslint-disable no-undef */
/* eslint-disable no-self-assign */
const lenisSettings = {
	duration: 1.2,
	easing: ( t ) => ( t === 1 ? 1 : 1 - Math.pow( 2, -10 * t ) ),
	direction: 'vertical',
	gestureDirection: 'vertical',
	smooth: true,
	smoothTouch: false,
	wheelMultiplier: 1.2,
	touchMultiplier: 0.2,
	// mouseMultiplier: 0.2,
	inifinite: false,
}
const lenis = new Lenis( lenisSettings );
lenis.on( 'scroll', ( e ) => {
	// console.log(e)
});
function raf( time ) {
	lenis.raf( time );
	requestAnimationFrame( raf );
}
requestAnimationFrame( raf );

gsap.registerPlugin( ScrollTrigger );

let preloaderTime = preloader?.classList.contains('show') ? 3000 : 0;
document.addEventListener('scroll', () => {
	preloaderTime = preloader?.classList.contains('show') ? 3000 : 0;
}, false);

function initAOS() {
	AOS.init({
		duration: 800,
		once: true,
		easing: 'ease-out-quad',
		offset: 50,
		anchorPlacement: 'bottom-bottom',
	});
}
setTimeout( initAOS, preloaderTime );

/* - - - - - - - - - - - - - -       fixed header       - - - - - - - - - - - - - - - */
let lastScrollTop = 0;
function fixedHeader() {
	const top = $( window ).scrollTop();
	if ( top > 0 ) {
		$( '.header' ).addClass( 'fixed' );
	} else {
		$( '.header' ).removeClass( 'fixed' );
	}
	if ( top > lastScrollTop && top > 0 ) {
		if ( !$( '.header-line-links' ).hasClass( 'active' ) ) {
			$( '.header' ).removeClass( 'show' ).css( 'transform', 'translateY(-' + $( '.header' ).innerHeight() + 'px)' );
		}
	} else {
		$( '.header' ).addClass( 'show' ).css( 'transform', '' );
	}
	lastScrollTop = top;
}
fixedHeader();
$( window ).on( 'scroll', fixedHeader );

function langToggle() {
	var toggle = $(".js-lang span");
	var list = $(".js-lang .lang-list");
	toggle.on("click", function() {
		list.toggleClass("open");
	} );

	// close lang menu on select lang
	$(".js-lang .lang-list a").on("click", function() {
		list.removeClass("open");
	} );

	// close lang menu on click outside
	$(document).click(function(event) {
		if (!$(event.target).closest(".js-lang, .lang-list ").length) {
			if ($(".lang-list").hasClass("open")) {
				$(".lang-list").removeClass("open");
			}
		}
	} );
}

function menuToggle() {

	// toggle burder menu
	$("#burger").click(function() {
		$(this).toggleClass("toggle");
		$("#menu").toggleClass("active");
	} );
}

function anchorScroll() {
	$("nav ul li a").click(function(event) {
		$("nav ul li a").removeClass("active");
		$(this).addClass("active");
		var href = $(this.hash);

		if (href.length) {
			event.preventDefault();
			$("html, body").animate( {
				scrollTop: href.offset().top - 100
			}, 750,
			function() {
				if (history.pushState) {
					history.pushState(null, null, "index.html#" + href.attr("id"));
				} else {
					location.hash = href.attr("id");
				}
			} );
			if ($("#menu").hasClass("active")) {
				$("#menu").removeClass("active");
				$("#burger").removeClass("toggle");
			}
		}
	} );

}

$(document).ready(function() {
	langToggle();
	menuToggle();
	anchorScroll();


	// modal default settings
	$.modal.defaults = {
		closeClass: "close",
		showClose: true,
		closeText: "<span class='icon icon-i-close'></span>",
		blockerClass: "jquery-modal"
	};


	// close menu on click outside
	$(document).click(function(event) {
		if (!$(event.target).closest("#burger, #menu").length) {
			if ($("#menu").hasClass("active")) {
				$("#menu").removeClass("active");
				$("#burger").removeClass("toggle");
			}
		}
	} );


	// if ($(".js-infinityLine-slider").length) {
	// 	$(".js-infinityLine-slider").slick( {
	// 		autoplay: true,
	// 		variableWidth: true,
	// 		arrows: false,
	// 		infinite: true,
	// 		speed: 5000,
	// 		autoplaySpeed: 0,
	// 		cssEase: "linear",
	// 		pauseOnFocus: false,

	// 		pauseOnHover: true,
	// 		useTransform: false
	// 	} );
	// }

	var speed = 0.5;

	const marqueesLeft = [ ...document.querySelectorAll(".marqueeLeft") ];
	marqueesLeft.forEach((marquee) => {
		marquee.innerHTML = marquee.innerHTML;
		marquee.i = 0;
		marquee.step = speed;
		marquee.width = marquee.clientWidth + 1;
		marquee.style.position = "";
		marquee.innerHTML = `${ marquee.innerHTML }`.repeat(30);

	//	marquee.addEventListener("mouseenter", () => (marquee.step = 0), false);
	//	marquee.addEventListener("mouseleave", () => (marquee.step = speed), false);
	} );

	const marqueesRight = [ ...document.querySelectorAll(".marqueeRight") ];
	marqueesRight.forEach((marquee) => {
		marquee.innerHTML = marquee.innerHTML;
		marquee.i = 0;
		marquee.step = speed;
		marquee.width = marquee.clientWidth + 1;
		marquee.style.position = "";
		marquee.innerHTML = `${ marquee.innerHTML }`.repeat(30);

	//	marquee.addEventListener("mouseenter", () => (marquee.step = 0), false);
	//	marquee.addEventListener("mouseleave", () => (marquee.step = speed), false);
	} );

	requestAnimationFrame(moveLeft);
	requestAnimationFrame(moveRight);

	function moveLeft() {
		marqueesLeft.forEach((marquee) => {
			marquee.style.marginLeft = `-${ marquee.i }px`;
			marquee.i = marquee.i < marquee.width ? marquee.i + marquee.step : 1;
		} );

		requestAnimationFrame(moveLeft);
	}
	function moveRight() {
		marqueesRight.forEach((marquee) => {
			marquee.style.marginRight = `-${ marquee.i }px`;
			marquee.i = marquee.i < marquee.width ? marquee.i + marquee.step : 1;
		} );

		requestAnimationFrame(moveRight);
	}

	if ($(".intro").length) {
		gsap.timeline({
			scrollTrigger: {
				trigger: ".intro",
				start: 'top top',
				endTrigger: '.intro-counter',
				end: 'center center',
				scrub: true,
				invalidateOnRefresh: true,
				// markers: true,
			}
		}).fromTo( "#coneCanvas", {
			opacity: 1,
			y: 0
		}, {
			opacity: 0.5,
			y: 100
		});
	}


	if ($(".intro-counter").length) {
		const cardList = gsap.utils.toArray(".counter-card");
		const mm = gsap.matchMedia();
		mm.add("(min-width: 768px)", () => {
			cardList.forEach((card, i) => {
				gsap.to( card, {
					scrollTrigger: {
						trigger: '.intro-counter',
						start: 'bottom bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
						// markers: true,
						onEnter: function() {
							setTimeout(() => {
								card.classList.add('show');
							}, preloaderTime);
						},
					}
				});
			});
		}).add("(max-width: 767px)", () => {
			cardList.forEach((card, i) => {
				gsap.to( card, {
					scrollTrigger: {
						trigger: card,
						start: 'center bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
						// markers: true,
						onEnter: function() {
							setTimeout(() => {
								card.classList.add('show');
							}, preloaderTime);
						},
					}
				});
			});
		});
		// const cardHeight = [280, 404, 528];
		// const mm = gsap.matchMedia();
		// mm.add("(min-width: 768px)", () => {
		// 	cardList.forEach((card, i) => {
		// 		gsap.fromTo( card, {
		// 			height: '0px'
		// 		}, {
		// 			height: `${cardHeight[i]}px`,
		// 			scrollTrigger: {
		// 				trigger: '.intro-counter',
		// 				start: `bottom bottom-=${i*30}`,
		// 				end: 'bottom center',
		// 				scrub: true,
		// 				markers: true
		// 			}
		// 		});
		// 	});
		// });
	}

	let heightStrategy = 0;
	function getHeightStrategy() {
		heightStrategy = $( '.strategyCard' ).innerHeight();
	}
	if ($(".strategyCard").length) {
		getHeightStrategy();
		window.addEventListener( 'resize', getHeightStrategy, false );

		const strategyList = gsap.utils.toArray(".strategyCard");
		const count = strategyList.length;
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.strategies-inner',
				pin: true,
				scrub: 0,
				start: 'center center',
				end: 'center+=' + heightStrategy * count + ' center',
				invalidateOnRefresh: true,
				// markers: true
			}
		});
		const mm = gsap.matchMedia();
		mm.add("(min-width: 768px)", () => {
			tl.to('.strategies-title', {
				x: () => -window.innerWidth / 4,
				y: 0,
				opacity: 0,
			});
		}).add("(max-width: 767px)", () => {
			tl.to('.strategies-title', {
				x: 0,
				y: () => -40,
				opacity: 0,
			});
		});

		mm.add("(min-width: 1200px)", () => {
			strategyList.forEach((card, i) => {
				const pos = i ? '+=0.5' : '';
				const cardWidth = strategyList[ 0 ].getBoundingClientRect().width;
				const posLeft = -window.innerWidth / 2 - cardWidth / 3 * 2;

				tl.fromTo( strategyList[ 0 ], {
					x: () => {
						if ( i === 0 ) {
							return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
						}
					},
					y: 0,
					background: 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)',
					zIndex: count
				}, {
					x: () => {
						if ( i === 0 ) {
							return 0;
						}
						if ( i === 1 ) {
							return posLeft;
						}
					},
					y: 0,
					duration: 1
				}, pos )
					.fromTo( strategyList[ 1 ], {
						scale: () => {
							if ( i === 0 ) {
								return 0.9;
							}
							if ( i === 1 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 3.5;
							}
							if ( i === 1 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						zIndex: count - 1
					}, {
						scale: () => {
							if ( i === 0 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
							}
							if ( i === 1 ) {
								return 0;
							}
							if ( i === 2 ) {
								return posLeft;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						duration: 1
					}, '-=1')
					.fromTo( strategyList[ 2 ], {
						scale: () => {
							if ( i === 0 ) {
								return 0.8;
							}
							if ( i === 1 ) {
								return 0.9;
							}
							if ( i === 2 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 5.5;
							}
							if ( i === 1 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 3.5;
							}
							if ( i === 2 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #243D8E 0%, #111567 100%)';
							}
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if ( i === 2 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						zIndex: count - 2
					}, {
						scale: () => {
							if ( i === 0 ) {
								return 0.9;
							}
							if ( i === 1 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 3.5;
							}
							if ( i === 1 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 40 * 1.5;
							}
							if ( i === 2 ) {
								return 0;
							}
							if ( i === 3 ) {
								return posLeft;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						duration: 1
					}, '-=1');
			});
		}).add("(min-width: 768px) and (max-width: 1199px)", () => {
			strategyList.forEach((card, i) => {
				const pos = i ? '+=0.5' : '';
				const cardWidth = strategyList[ 0 ].getBoundingClientRect().width;
				const posLeft = -window.innerWidth / 2 - cardWidth / 3 * 2;
				tl.fromTo( strategyList[ 0 ], {
					x: () => {
						if ( i === 0 ) {
							return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
						}
					},
					y: 0,
					background: 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)',
					zIndex: count
				}, {
					x: () => {
						if ( i === 0 ) {
							return 0;
						}
						if ( i === 1 ) {
							return posLeft;
						}
					},
					y: 0,
					duration: 1
				}, pos )
					.fromTo( strategyList[ 1 ], {
						scale: () => {
							if ( i === 0 ) {
								return 0.95;
							}
							if ( i === 1 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 3.5;
							}
							if ( i === 1 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						zIndex: count - 1
					}, {
						scale: () => {
							if ( i === 0 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
							}
							if ( i === 1 ) {
								return 0;
							}
							if ( i === 2 ) {
								return posLeft;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						duration: 1
					}, '-=1')
					.fromTo( strategyList[ 2 ], {
						scale: () => {
							if ( i === 0 ) {
								return 0.9;
							}
							if ( i === 1 ) {
								return 0.95;
							}
							if ( i === 2 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 5.5;
							}
							if ( i === 1 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 3.5;
							}
							if ( i === 2 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #243D8E 0%, #111567 100%)';
							}
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if ( i === 2 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						zIndex: count - 2
					}, {
						scale: () => {
							if ( i === 0 ) {
								return 0.95;
							}
							if ( i === 1 ) {
								return 1;
							}
						},
						x: () => {
							if ( i === 0 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 3.5;
							}
							if ( i === 1 ) {
								return window.innerWidth - (window.innerWidth - cardWidth) / 2 - 15 * 1.5;
							}
							if ( i === 2 ) {
								return 0;
							}
							if ( i === 3 ) {
								return posLeft;
							}
						},
						y: 0,
						background: () => {
							if ( i === 0 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						duration: 1
					}, '-=1');
			});
		}).add("(max-width: 767px)", () => {
			strategyList.forEach((card, i) => {
				const pos = i ? '+=0.5' : '';
				const cardWidth = strategyList[ 0 ].getBoundingClientRect().width;
				const posLeft = -window.innerWidth / 2 - cardWidth / 3 * 2;
				tl.fromTo( strategyList[ 0 ], {
					x: 0,
					y: () => {
						if ( i === 0 ) {
							return window.innerHeight;
						}
						if ( i === 1 ) {
							return 0;
						}
						if ( i === 2 ) {
							return -30;
						}
						if ( i === 3 ) {
							return -60;
						}
					},
					background: () => {
						if ( i <= 1 ) {
							return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)';
						}
						if ( i === 2 ) {
							return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
						}
					},
					zIndex: ''
				}, {
					scale: () => {
						if ( i === 0 ) {
							return 1;
						}
						if ( i === 1 ) {
							return 0.95;
						}
						if ( i === 2 ) {
							return 0.9;
						}
						if ( i === 3 ) {
							return 0.85;
						}
					},
					x: 0,
					y: () => {
						if ( i === 0 ) {
							return 0;
						}
						if ( i === 1 ) {
							return -30;
						}
						if ( i === 2 ) {
							return -60;
						}
						if ( i === 3 ) {
							return -90;
						}
					},
					background: () => {
						if ( i === 0 ) {
							return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
						}
						if ( i === 1 ) {
							return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
						}
						if ( i === 2 ) {
							return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #243D8E 0%, #111567 100%)';
						}
					},
					duration: 1
				}, pos )
					.fromTo( strategyList[ 1 ], {
						x: 0,
						y: () => {
							if ( i <= 1 ) {
								return window.innerHeight;
							}
							if ( i === 2 ) {
								return 0;
							}
							if ( i === 3 ) {
								return -30;
							}
						},
						background: () => {
							if ( i <= 2 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)';
							}
							if ( i === 3 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
						},
						zIndex: ''
					}, {
						scale: () => {
							if ( i === 1 ) {
								return 1;
							}
							if ( i === 2 ) {
								return 0.95;
							}
							if ( i === 3 ) {
								return 0.9;
							}
						},
						x: 0,
						y: () => {
							if ( i === 1 ) {
								return 0;
							}
							if ( i === 2 ) {
								return -30;
							}
							if ( i === 3 ) {
								return -60;
							}
						},
						background: () => {
							if ( i === 1 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
							if ( i === 2 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000B30 100%)';
							}
						},
						duration: 1
					}, '-=1')
					.fromTo( strategyList[ 2 ], {
						x: 0,
						y: () => {
							if ( i === 2 ) {
								return window.innerHeight;
							}
						},
						background: 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000 100%)',
						zIndex: ''
					}, {
						scale: () => {
							if ( i === 2 ) {
								return 1;
							}
							if ( i === 3 ) {
								return 0.95;
							}
						},
						x: 0,
						y: () => {
							if ( i === 2 ) {
								return 0;
							}
							if ( i === 3 ) {
								return -30;
							}
						},
						background: () => {
							if ( i === 2 ) {
								return 'radial-gradient(118.49% 140.06% at -4.04% -29.21%, #0E2163 0%, #000000 100%)';
							}
						},
						duration: 1
					}, '-=1');
			});
		});
	}

	if ( $( "#gridCanvas" ).length ) {
		gsap.timeline({
			scrollTrigger: {
				trigger: '.mapBlock-map',
				scrub: true,
				start: 'top 75%',
				end: 'top 25%',
				invalidateOnRefresh: true,
				// markers: true
			}
		}).fromTo( '#gridCanvas', {
			scale: 2.5,
			rotationX: 90,
			opacity: 0,
			duration: 1
		}, {
			scale: 1,
			rotationX: 0,
			opacity: 0.5
		});
	}

	if ($(".mapBlock-map").length) {
		gsap.timeline({
			scrollTrigger: {
				trigger: '.mapBlock-map',
				scrub: true,
				start: 'top 75%',
				end: 'top 25%',
				invalidateOnRefresh: true,
				// markers: true
			}
		}).fromTo( '.mapBlock-map-vector', {
			scale: 2.5,
			opacity: 0,
			duration: 1
		}, {
			scale: 1,
			opacity: 1
		}, 0 );

		gsap.timeline({
			scrollTrigger: {
				trigger: '.mapBlock-map',
				scrub: true,
				start: 'top 75%',
				end: 'top 50%',
				invalidateOnRefresh: true,
				// markers: true
			}
		}).fromTo( '.mapBlock-map-vector', {
			rotationX: 90,
			duration: 1
		}, {
			rotationX: 0,
		}).fromTo( '#gridAngleCanvas', {
			opacity: 1
		}, {
			opacity: 0
		}, '-=0.5' ).fromTo( '.mapBlock-map-title', {
			y: 60,
			opacity: 0,
			duration: 1
		}, {
			y: 0,
			opacity: 1
		}, '-=0.25' );
		// .fromTo('.conceptBlock-left', {
		// 		x: () => -window.innerWidth / 2
		// 	},
		// 	{
		// 		x: 0
		// 	}, '<'
		// ).fromTo('.conceptBlock-right', {
		// 		x: () => window.innerWidth / 2
		// 	},
		// 	{
		// 		x: 0
		// 	}, '<'
		// ).to('.conceptBlock-line', {
		// 		opacity: 1,
		// 		duration: 0
		// 	}
		// ).to('.conceptBlock-line', {
		// 		height: 32
		// 	}
		// );
	}


	const target = document.querySelector(".textBlock");
	const results = Splitting( { target: target, by: "lines" } );

	const textAnimation = gsap.timeline( {
		scrollTrigger: {

			//pinSpacing: false, // You almost always want pinSpacing: true
			trigger: ".textBlock",
			scrub: 2,
			start: "top 50%",
			end: "bottom 70%",
			pin: ".pin",
			invalidateOnRefresh: true,
			// markers: true
		}
	} );
	textAnimation.to(".word", {
		"--highlight-offset": "100%",
		stagger: 0.5
	} );

	let heightApproach = 0;
	function getHeightApproach() {
		heightApproach = $( '.approach-card-main' ).innerHeight();
	}
	if ($(".approach-row").length) {
		getHeightApproach();
		window.addEventListener( 'resize', getHeightApproach, false );

		const approachList = gsap.utils.toArray(".approach-card-main");
		const approachTitle = gsap.utils.toArray( '.approach-card-after' );
		const count = approachList.length;
		const tl = gsap.timeline({
			default: {
				ease: 'none'
			},
			scrollTrigger: {
				trigger: '.approach-row',
				pin: true,
				scrub: 0,
				start: 'center center',
				end: 'center+=' + heightApproach * count + ' center',
				invalidateOnRefresh: true,
				// markers: true
			}
		});
		// const mm = gsap.matchMedia();
		// mm.add("(min-width: 768px)", () => {
			approachList.forEach((card, i) => {
				const pos = i ? '+=0.5' : '';
				if ( approachList[ i + 1 ] ) {
					tl.to( approachList[ i ], {
						opacity: 0.8,
						scale: 0.9,
						y: -36
					}, pos )
					.fromTo( approachList[ i + 1 ], {
						opacity: 0,
						scale: 1,
						y: 100
					}, {
						opacity: 1,
						scale: 1,
						y: 0
					}, '<' )
					.to( approachTitle[ i ], {
						opacity: 0,
						y: -36
					},'-=0.5')
					.fromTo( approachTitle[ i + 1 ], {
						opacity: 0,
						y: 100
					}, {
						opacity: 1,
						y: 0
					}, '<' );
				}
			});
		// });
	}


	if ($(".userCard").length) {
		const teamList = gsap.utils.toArray(".userCard");
		const mm = gsap.matchMedia();
		mm.add("(min-width: 1024px)", () => {
			teamList.forEach((card, i) => {
				gsap.to( card, {
					scrollTrigger: {
						trigger: card,
						start: 'bottom bottom',
						end: 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,
						// markers: true,
						onEnter: function() {
							setTimeout(() => {
								card.querySelector('.userCard-box').classList.add('show');
							}, preloaderTime);
						},
					}
				});
			});
		}).add("(max-width: 1023px)", () => {
			teamList.forEach((card, i) => {
				gsap.to( card, {
					scrollTrigger: {
						trigger: card,
						start: 'center bottom',
						end: 'bottom top',
						scrub: true,
						// markers: true,
						onEnter: function() {
							setTimeout(() => {
								card.querySelector('.userCard-box').classList.add('show');
							}, preloaderTime);
						},
					}
				});
			});
		});
	}

	if ( $( "#gridCanvas" ).length ) {
		gsap.timeline({
			scrollTrigger: {
				trigger: '.brands',
				scrub: true,
				start: 'top bottom',
				end: 'top 50%',
				invalidateOnRefresh: true,
				// markers: true
			}
		}).fromTo( '#gridCanvas', {
			scale: 1,
			opacity: 1,
			duration: 1
		}, {
			scale: 0,
			opacity: 0
		});
	}

	if ($(".conceptBlock").length) {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.conceptBlock',
				pin: true,
				scrub: 0,
				start: 'top bottom',
				end: 'top center-=36',
				invalidateOnRefresh: true,
				// markers: true,
				onEnterBack: () => {
					$( '.footer' ).removeClass( 'fixed' );
				},
				onLeave: () => {
					$( '.footer' ).addClass( 'fixed' );
				}
			}
		}).fromTo('.conceptBlock-left', {
				x: () => -window.innerWidth / 2
			},
			{
				x: 0
			}, '<'
		).fromTo('.conceptBlock-right', {
				x: () => window.innerWidth / 2
			},
			{
				x: 0
			}, '<'
		).to('.conceptBlock-line', {
				opacity: 1,
				duration: 0
			}
		).to('.conceptBlock-line', {
				height: 32
			}
		);

		const tl2 = gsap.timeline({
			scrollTrigger: {
				trigger: '.footer',
				scrub: 0,
				start: 'top 50%',
				end: 'bottom bottom',
				invalidateOnRefresh: true,
				// markers: true,
				onEnterBack: () => {
					$( '.footer' ).removeClass( 'animated' );
				},
				onLeave: () => {
					$( '.footer' ).addClass( 'animated' );
				}
			}
		}).to('.conceptBlock-main', {
				opacity: 1
			}
		);
	}
});


// class GradientAnimation {
// 	constructor() {
// 		this.cnv = document.querySelector("canvas");
// 		this.ctx = this.cnv.getContext("2d");

// 		this.circlesNum = 1;
// 		this.speed = 0.005;
// 		this.minRadius = 400;
// 		this.maxRadius = 800;

// 		this.setCanvasSize();

// 		this.createCircle();
// 		this.createCircle2();
// 		this.drawAnimation();
// 		var resizePerfomance;
// 		window.onresize = () => {
// 			clearTimeout(resizePerfomance);
// 			resizePerfomance = setTimeout(() => {

// 				this.setCanvasSize();

// 				this.createCircle();
// 				this.createCircle2();
// 			}, 100);
// 		};
// 	}
// 	setCanvasSize() {
// 		this.w = this.cnv.width = innerWidth;
// 		this.h = this.cnv.height = innerHeight;
// 	}
// 	createCircle() {
// 		this.circles = [];
// 		for (let i = 0; i < this.circlesNum; ++i) {
// 			this.circles.push(new Circle(this.w, this.h, this.minRadius, this.maxRadius));
// 		}
// 	}

// 	drawCircles() {
// 		this.circles.forEach(circle => circle.draw(this.ctx, this.speed));
// 	}
// 	clearCanvas() {
// 		this.ctx.clearRect(0, 0, this.w, this.h);
// 	}
// 	drawAnimation() {
// 		this.clearCanvas();
// 		this.drawCircles();
// 		window.requestAnimationFrame(() => this.drawAnimation());
// 	}
// }

// class Circle {
// 	constructor(w, h, minR, maxR) {
// 		this.x = Math.random() * w;
// 		this.y = Math.random() * h;
// 		this.angle = Math.random() * 2 * Math.PI;
// 		this.radius = Math.random() * (maxR - minR) + minR;
// 		this.firstColor = "hsla(0, 0%, 100%, 1)";
// 		this.secondColor = "hsla(226, 100%, 62%, 0)";
// 	}
// 	draw(ctx, speed) {
// 		this.angle += speed;
// 		const x = this.x + Math.cos(this.angle) * 200;
// 		const y = this.y + Math.sin(this.angle) * 200;
// 		const gradient = ctx.createRadialGradient(x, y, 0, x, y, this.radius);
// 		gradient.addColorStop(0, this.firstColor);
// 		gradient.addColorStop(1, this.secondColor);

// 		ctx.globalCompositeOperation = "overlay";
// 		ctx.fillStyle = gradient;
// 		ctx.beginPath();
// 		ctx.arc(x, y, this.radius, 0, 2 * Math.PI);
// 		ctx.fill();
// 	}
// }

// class Circle2 {
// 	constructor(w, h, minR, maxR) {
// 		this.x = Math.random() * w;
// 		this.y = Math.random() * h;
// 		this.angle = Math.random() * 2 * Math.PI;
// 		this.radius = Math.random() * (maxR - minR) + minR;
// 		this.firstColor = "hsla(226, 70%, 40%, 1)";
// 		this.secondColor = "hsla(226, 100%, 62%, 0)";
// 	}
// 	draw(ctx, speed) {
// 		this.angle += speed;
// 		const x = this.x + Math.cos(this.angle) * 300;
// 		const y = this.y + Math.sin(this.angle) * 300;
// 		const gradient = ctx.createRadialGradient(x, y, 0, x, y, this.radius);
// 		gradient.addColorStop(0, this.firstColor);
// 		gradient.addColorStop(1, this.secondColor);

// 		ctx.globalCompositeOperation = "overlay";
// 		ctx.fillStyle = gradient;
// 		ctx.beginPath();
// 		ctx.arc(x, y, this.radius, 0, 2 * Math.PI);
// 		ctx.fill();
// 	}
// }

// window.onload = () => new GradientAnimation();

